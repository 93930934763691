<template>
  <div
    class="cpt-MMC_Gimbal_Z30Pro"
    :style="containerStyle"
    :class="{ heightOne: routePath == true }"
  >
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机Z30Pro</div>
      </div>
      <div class="close" @click="close">关闭</div>
    </div>
    <div class="bd" :class="{ heightTwo: routePath == true }">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_change"
              size="mini"
              placeholder="请选择控制选项"
              @change="onChangeType"
            >
              <el-option
                v-for="(item, index) in controlist"
                :key="index"
                :label="item.type"
                :value="item.id"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio size="mini" v-for="item in drd.ptz_mode" :key="item.id" :label="item.id">{{ item.label }}
              </el-radio>
            </el-radio-group> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">视频指点:</div>
          <div class="input-box">
            <el-radio-group v-model="dj_mode" @change="handle_change_dj_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10 cp"
              @mousedown="handle_zoom(-1)"
              @mouseup="stopChange"
              @mouseleave="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button> -->
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              @input="onChangezoom"
              @mouseout="value = 25"
              @mouseleave="value = 25"
            />

            <div
              class="ml10 cp"
              @mousedown="handle_zoom(1)"
              @mouseup="stopChange"
              @mouseleave="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in regList"
                :key="index"
                class="pitch"
                :style="
                  pitch == item.num ? 'background: #FFFFFF;color: #333333;' : ''
                "
                @click="handle_change_pitch(item.num)"
              >
                {{ item.val }}
              </div>
            </div>
            <!-- <el-input v-model.number="pitch" size="mini" placeholder="请输入俯仰" />
            <span class="iconfont icon-shanchu3" @click="pitch--"></span>
            <span class="iconfont icon-tianjia1 mr5 ml3" @click="pitch++"></span>
            <div class="butto" @click="handle_change_pitch" size="mini" type="primary">设置</div> -->
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">
            点&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;击
          </div>
          <div class="input-box">
            <el-radio-group
              v-model="click_mode"
              @change="handle_change_click_mode"
            >
              <el-radio
                v-for="item in drd.click_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">宽&nbsp;&nbsp;动&nbsp;&nbsp;态</div>
          <div class="input-box">
            <el-radio-group v-model="wid_mode" @change="handle_change_wid_mode">
              <el-radio
                v-for="item in drd.wid_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">
            透&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;雾
          </div>
          <div class="input-box">
            <el-radio-group v-model="fog_mode" @change="handle_change_fog_mode">
              <el-radio
                v-for="item in drd.fog_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">联控模式</div>
          <div class="input-box">
            <el-radio-group
              v-model="control_mode"
              @change="handle_change_control_mode"
            >
              <el-radio
                v-for="item in drd.control_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input v-model.number="yaw" size="mini" placeholder="请输入航向" />
            <span class="iconfont icon-shanchu3" @click="yaw--"></span>
            <span class="iconfont icon-tianjia1 mr5 ml3" @click="yaw++"></span>
            <div class="butto" @click="handle_change_yaw" size="mini" type="primary">设置</div>
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">电子增稳</div>
          <div class="input-box">
            <el-radio-group
              v-model="stable_mode"
              @change="handle_change_stable_mode"
            >
              <el-radio
                v-for="item in drd.stable_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">可见度增强</div>
          <div class="input-box">
            <el-radio-group v-model="vis_mode" @change="handle_change_vis_mode">
              <el-radio
                v-for="item in drd.vis_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            降&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;噪
          </div>
          <div class="input-box">
            <el-radio-group
              v-model="noise_mode"
              @change="handle_change_noise_mode"
            >
              <el-radio
                v-for="item in drd.noise_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍</div>
          <div class="input-box">
            <el-radio-group v-model="ele_mode" @change="handle_change_ele_mode">
              <el-radio
                v-for="item in drd.ele_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">视频输出</div>
          <div class="input-box">
            <el-radio-group
              v-model="video_mode"
              @change="handle_change_video_mode"
            >
              <el-radio
                v-for="item in drd.video_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">夜间模式</div>
          <div class="input-box">
            <el-radio-group
              v-model="night_mode"
              @change="handle_change_night_mode"
            >
              <el-radio
                v-for="item in drd.night_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">白&nbsp;&nbsp;平&nbsp;&nbsp;衡</div>
          <div class="input-box">
            <el-radio-group
              v-model="white_mode"
              @change="handle_change_white_mode"
            >
              <el-radio
                v-for="item in drd.white_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">饱和度</div>
          <div class="input-box">
            <el-button @click="handle_saturated(1)" size="mini" icon="el-icon-plus" circle></el-button>
            <el-button @click="handle_saturated(-1)" size="mini" icon="el-icon-minus" circle></el-button>
          </div>
        </div> -->
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div class="item-box" @click="handle_take_photo">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt="" />
              </div>
            </el-tooltip>
          </div>
          <div class="item-box" @click="handle_record">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img
                  v-if="!record"
                  src="~@/assets/images/mount/record.png"
                  alt=""
                />
                <img v-else src="~@/assets/images/mount/stop.png" alt="" />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div ref="rocker" class="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="zuoUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="youUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_Z30Pro } from "./js/index";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    /* taskId: {
      type: Boolean,
      default: ""
    }, */
    position: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      routePath: "", // 判断当前路径
      holderModelDomV2: 1,
      controlist: [
        {
          id: 4,
          type: "一键向下",
        },
        {
          id: 3,
          type: "航向回中",
        },
        {
          id: 5,
          type: "俯仰水平",
        },
        {
          id: 2,
          type: "回中",
        },
      ],
      control_change: 2,
      radio: 1,
      zdid: 1,
      value: 25,
      value2: 25,
      control_speed: 3,
      click_mode: 0,
      ptz_mode: 1,
      wid_mode: 0,
      stable_mode: 1,
      vis_mode: 0,
      noise_mode: 1,
      ele_mode: 0,
      video_mode: 0,
      night_mode: 0,
      white_mode: 0,
      control_mode: 0,
      zoom: 1,
      pitch: 0,
      yaw: 0,
      fog_mode: 0,
      record: false,
      dj_mode: 0,
      regList: [
        {
          val: "复位",
          num: 0,
        },
        {
          val: "30",
          num: 30,
        },
        {
          val: "60",
          num: 60,
        },
        {
          val: "90",
          num: 90,
        },
      ],
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        click_mode: [
          { id: 0, label: "移动" },
          { id: 1, label: "聚焦" },
          { id: 2, label: "曝光" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        wid_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        stable_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "超级" },
          { id: 2, label: "超级+" },
        ],
        control_mode: [
          { id: 0, label: "关" },
          { id: 1, label: "开" },
        ],
        vis_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        noise_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ele_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        video_mode: [
          { id: 0, label: "1080p60" },
          { id: 1, label: "1080p30" },
          { id: 2, label: "720p60" },
        ],
        night_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "黑白" },
          { id: 2, label: "彩色" },
        ],
        white_mode: [
          { id: 0, label: "自动" },
          { id: 1, label: "室内" },
          { id: 2, label: "室外" },
        ],
        fog_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
      },
      timer: null,
      keyFlag: false,
      // 录像
      screenRecordingStatus: true,
    };
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
  },
  watch: {
    "$store.state.uavApplications.healthData": {
      handler(newval) {
        let arrKey = [];
        for (let i in newval) {
          arrKey.push(i);
        }
        let flage = arrKey.some((val) => val == "NX");
        if (flage && newval["NX"].warningLevel == "NORMAL") {
          this.keyFlag = true;
        } else {
          this.keyFlag = false;
        }
      },
      deep: true,
    },
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
    // console.log('1111111111111111111111111111----');
    if (this.$route.path.replace("/", "") == "fckernel") {
      this.routePath = true;
    }
    //  console.log(this.$route.path.replace('/',''),'-----------111111111111111');
  },
  methods: {
    close() {
      if (this.record) {
        this.$message.info("请结束录像后再关闭！");
      } else {
        this.$emit("close");
      }
    },
    handle_change_dj_mode(id) {
      if (id == 0) {
        this.$store.commit("uavApplications/setState", {
          key: "move_data",
          value: { mountStatus: 0, name: "MMC_Gimbal_Z30Pro" },
        });
      }else{
        this.$store.commit("uavApplications/setState", {
          key: "move_data",
          value: { mountStatus: id, name: "MMC_Gimbal_Z30Pro" },
        });

      }
    },
    handle_change_zd(id) {
      console.log(id);
      console.log(this.device, "device");
    },
    onChangezoom(e) {
      if (e < 25) {
        this.handle_zoom(-1);
      } else if (e > 25) {
        this.handle_zoom(1);
      } else {
        const buffer = MMC_Gimbal_Z30Pro.zoom((this.zoom = 1));
        this.commit_directive(buffer);
      }
    },
    mousedownFn() {
      // console.log(mousedownFn, "mousedownFn");
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      const buffer = MMC_Gimbal_Z30Pro.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    resolve_payload(buff) {
      const dataArray = new Uint8Array(buff);
      if (
        dataArray[0] === 0xa5 &&
        cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        switch (dataArray[1]) {
          case 0x01:
            this.msg_key_status1(dataArray);
            break;
          case 0xf8:
            this.gcs_button_ctrl(dataArray);
            break;
        }
      }
    },
    gcs_button_ctrl(data) {
      const button = data[3];
      const status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },
    handle_change_click_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.give_directions_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      const buffer = MMC_Gimbal_Z30Pro.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_wid_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.camera_WD_ctrl(value);
      console.log(buffer, "budd");
      this.commit_directive(buffer);
    },
    handle_change_stable_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.stabilizer_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_control_mode(mode) {
      const buffer = MMC_Gimbal_Z30Pro.control_mode_ctrl(mode);
      this.commit_directive(buffer);
    },
    handle_change_vis_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.VE_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_noise_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.NR_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_ele_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.DZOOM_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_video_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.videooutput_mode_ctrl(value);
      this.commit_directive(buffer);
      buffer = [
        165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 25, 3, 255, 151, 254,
      ];
      this.commit_directive(buffer);
    },
    handle_change_night_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.ICR_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_white_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.WB_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_saturated(value) {
      const buffer = MMC_Gimbal_Z30Pro.saturated(this.zoom + value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      const buffer = MMC_Gimbal_Z30Pro.zoom(this.zoom + value);
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_Z30Pro.zoom((this.zoom = 1));
      // this.commit_directive(buffer);
    },
    handle_change_pitch(num) {
      this.pitch = num;
      const buffer = MMC_Gimbal_Z30Pro.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z30Pro.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      const buffer = MMC_Gimbal_Z30Pro.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_fog_mode(mode) {
      const buffer = MMC_Gimbal_Z30Pro.camera_Defog_ctrl(mode);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      if (!this.keyFlag) return this.$message.error("拍照失败，NX通信异常！");
      this.$store.dispatch("uavApplications/TakePhoto", {
        callback: (isOk) => {
          isOk && console.log("拍照");
        },
      });
    },
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，NX通信异常！");
      this.record = !this.record;
      // 录像
      this.screenRecording();
    },
    screenRecording() {
      this.$store.dispatch("uavApplications/videoTranscribe", {
        videoID: 1, // 视频通道ID（需保持唯一）
        dbID: "929", // 历史记录id
        taskID: "", // 任务ID，可传可不传
        recordControl: true, // 录制开关、true为开启
        status: this.screenRecordingStatus,
        callback: (isOk) => {
          isOk &&
            this.$message.success(
              `${this.screenRecordingStatus ? "开始" : "结束"}录屏`
            );
          this.screenRecordingStatus = !this.screenRecordingStatus;
        },
      });
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    onChangeType(val) {
      const buffer = MMC_Gimbal_Z30Pro.gimbal_mode_ctrl(val);
      this.commit_directive(buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          console.log(this.control_speed);
          buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(1);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(1);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(-1);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(-1);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 2000);
    },
    stopfxFn() {
      let buffer = null;
      buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              console.log(this.control_speed);
              buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(
                1,
                this.control_speed
              );
              this.commit_directive(buffer);
              break;
            case "right":
              buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "left":
              buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(
                -1,
                this.control_speed
              );
              this.commit_directive(buffer);
              break;
            case "down":
              buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(
                -1,
                this.control_speed
              );
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z30Pro {
  position: absolute;
  // top: -138px;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 470px;
  height: 290px;

  box-sizing: border-box;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    height: 230px;
    overflow-x: hidden;

    .form-wrap {
      // flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          text-align: right;
          white-space: nowrap;
          color: #cbd0eb;
          width: 75px;
        }

        .btn-default {
          padding: 0 10px !important;
          background: #2aefed;
          border-color: #2aefed;
        }

        .input-box {
          margin-left: 15px;
          display: flex;
          align-items: center;
          .mono {
            display: flex;
            flex-direction: column;
            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }
              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      position: absolute;
      right: 30px;
      // flex: 1;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #004fff;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        // margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    white-space: nowrap;
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .active {
    background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
    // background: url("~@/assets/images/accident/mono_box.svg") no-repeat !important;
    background-size: 100% 100%;
  }
  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}

::v-deep .el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
.heightOne {
  height: 298px !important;
}
.heightTwo {
  height: 230px !important;
}
</style>
